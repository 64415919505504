import {useEffect, useState} from "react";
import {Formik} from "formik";
import {Accordion, Button, Col, Container, Form, Row} from "react-bootstrap";
import {FaSearch} from "react-icons/all";

import ModalInfo from "../ModalInfo";
import {
  explanations,
  zipcodeUrl
} from "../global";

import "./SunHoursStep.css";

const defaultSunHours = 5; // Default

const SunHoursStep = ({ onChangeSunHours = () => {}, sunhours = defaultSunHours}) => {
  const [sunHours, setSunHours] = useState(Number(sunhours));
  const [zipcode, setZipcode] = useState("");

  useEffect(() => {
    if (sunhours) {
      setSunHours(sunhours);
    }
  }, [sunhours]);

  const handleSubmitForm = ({zipcode}) => {
    if (zipcode) {
      setZipcode(zipcode);
      fetch(zipcodeUrl + zipcode)
        .then((res) => res.json())
        .then((res) => {
          const value = res?.outputs?.avg_dni?.annual ?? defaultSunHours;
          setSunHours(value);
          onChangeSunHours(value);
        })
        .catch(() => {
          setSunHours(defaultSunHours);
          onChangeSunHours(defaultSunHours);
        });
    }
  };

  const onChangeHour = (e) => {
    setSunHours(e.target.value);
    onChangeSunHours(e.target.value);
  };

  return (
    <Container className="App-SunHours-container">
      <Accordion className="App-step-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Step 1: Sun hours per day</Accordion.Header>
          <Accordion.Body className="App-step-body">
            <Formik
              onSubmit={handleSubmitForm}
              enableReinitialize={true}
              initialValues={{
                zipcode,
                sunhours: sunHours,
              }}
            >
              {({
                  handleSubmit,
                  handleChange,
                  values,
                }) => (
                <Form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  role="form"
                >
                  <Row className="g-1">
                    <Form.Group as={Col} controlId="zipcode">
                      <Form.Label>
                        Average  sun hours per day:
                        <ModalInfo
                          title={explanations.zipcode.title}
                          content={explanations.zipcode.content}
                        />
                      </Form.Label>

                      <div className="App-SunHours">
                        <div className="App-SunHours-zipCode">
                          <Form.Control
                            type="text"
                            value={values.zipcode}
                            placeholder="Zipcode."
                            onChange={handleChange}
                          />
                          <Button
                            type="submit"
                            variant="warning"
                            color="white"
                          >
                            <FaSearch />
                            {" FIND"}
                          </Button>
                        </div>
                        <div className="App-SunHours-average">
                          <span>Sun Hours/Day</span>
                          <Form.Control
                            type="number"
                            step="0.01"
                            value={sunHours}
                            onChange={onChangeHour}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Row>

                </Form>
              )}

            </Formik>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
};

export default SunHoursStep;
