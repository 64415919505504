const explanations = {
  zipcode: {
    title: "Zip Code sun hours per day",
    content: "Enter your zipcode to find out your average sun hours per day"
  },
  appliances: {
    title: "List of appliances",
    content: "Fill out the load list by entering all appliances you are planning to use. This will allow to size your futur solar system with more granularity."
  },
  daysup: {
    title: "Days of autonomy",
    content: "Enter the maximum number of days you wants to have as backup to power your system. In case of cloudy or rainy days, your system will mainly use your batteries as source of power and you want to be sure you size correctly the battery bank base on your consumption."
  }
};

const nrelKey = "IvIcm4PGFNW6dk7sOPG484KgTy7l0bbokqYCfX2n";
const zipcodeUrl = "https://developer.nrel.gov/api/solar/solar_resource/v1.json?api_key="+nrelKey+"&address=";

export {
  explanations,
  zipcodeUrl
};
