import {useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";

import { buildUrlParam } from "../tools/convert";

import {HiOutlineClipboardCopy} from "react-icons/all";
import "./ShareLinkStep.css";

const ShareLinkStep = ({ sunhours = 0, dayautonomy, currentAppliances}) => {
  const [url, setUrl] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const urlWithParams = buildUrlParam(sunhours, currentAppliances, dayautonomy);
    setUrl(urlWithParams.href);
  },[currentAppliances, dayautonomy, sunhours]);

  const copyTextToClipboard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(url);
    } else {
      return document.execCommand("copy", true, url);
    }
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard()
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      });
  };

  if (!currentAppliances.length) {
    return null; // Do not display any share link
  }

  return (
    <Container className="App-ShareLink-container">
      <h3>Share/Save:</h3>
      <Form
        className="form-horizontal"
        onSubmit={()=>{}}
        role="form"
      >
        <Row className="g-1">
          <Form.Group as={Col} controlId="sharelink">
            <Form.Label>
              Copy this link to save your setup
            </Form.Label>

            <div className="App-ShareLink-copy">
              <Form.Control
                type="text"
                defaultValue={url}
                style={{width: "50%"}}
              />
              <Button
                style={{width: "50%"}}
                onClick={handleCopyClick}
              >
                <HiOutlineClipboardCopy />{isCopied ? " Link Copied!" : " Copy Link"}
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </Container>
  )
};

export default ShareLinkStep;
