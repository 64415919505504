const arraySize = [
  1200,
  2400,
  3600,
  4800,
  6000,
  7200,
  8400,
  9600,
  10800,
  12000
];

const batteriesSize = {
  1200: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 1,
    },
  ],
  2400: [
    {
      amp: 200,
      volt: 12,
      model: "12V200AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B088RM4W48?_encoding=UTF8&psc=1&linkCode=li2&tag=sollife-20&linkId=ae01a116f34716acc2b7cbbc9d789dc5&language=en_US&ref_=as_li_ss_il",
      count: 1,
    },
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 2,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AHPLUS",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6HH4WK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6HH4WK?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=fdd1cf22ade11a286306e444caf549f6&language=en_US&ref_=as_li_ss_il",
      count: 1,
    },
    {
      amp: 100,
      volt: 24,
      model: "24V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6LP1J9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6LP1J9?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=43822e6baee1e47acf7f24eb08bdcd2a&language=en_US&ref_=as_li_ss_il",
      count: 1,
    },
  ],
  3600: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 3,
    },
  ],
  4800: [
    {
      amp: 200,
      volt: 12,
      model: "12V200AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B088RM4W48?_encoding=UTF8&psc=1&linkCode=li2&tag=sollife-20&linkId=ae01a116f34716acc2b7cbbc9d789dc5&language=en_US&ref_=as_li_ss_il",
      count: 2,
    },
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 4,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AHPLUS",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6HH4WK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6HH4WK?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=fdd1cf22ade11a286306e444caf549f6&language=en_US&ref_=as_li_ss_il",
      count: 2,
    },
    {
      amp: 100,
      volt: 24,
      model: "24V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6LP1J9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6LP1J9?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=43822e6baee1e47acf7f24eb08bdcd2a&language=en_US&ref_=as_li_ss_il",
      count: 2,
    },
    {
      amp: 100,
      volt: 48,
      model: "48V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08RSLP1PQ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08RSLP1PQ?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=8745111329b17ed1fb019ac3a5abb61a&language=en_US&ref_=as_li_ss_il",
      count: 1,
    },
  ],
  6000: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 5,
    },
  ],
  7200: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 6,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B088RM4W48?_encoding=UTF8&psc=1&linkCode=li2&tag=sollife-20&linkId=ae01a116f34716acc2b7cbbc9d789dc5&language=en_US&ref_=as_li_ss_il",
      count: 3,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AHPLUS",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6HH4WK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6HH4WK?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=fdd1cf22ade11a286306e444caf549f6&language=en_US&ref_=as_li_ss_il",
      count: 3,
    },
    {
      amp: 100,
      volt: 24,
      model: "24V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6LP1J9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6LP1J9?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=43822e6baee1e47acf7f24eb08bdcd2a&language=en_US&ref_=as_li_ss_il",
      count: 3,
    },
  ],
  8400: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 7,
    },
  ],
  9600: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 8,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B088RM4W48?_encoding=UTF8&psc=1&linkCode=li2&tag=sollife-20&linkId=ae01a116f34716acc2b7cbbc9d789dc5&language=en_US&ref_=as_li_ss_il",
      count: 4,
    },
    {
      amp: 100,
      volt: 24,
      model: "24V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6LP1J9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6LP1J9?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=43822e6baee1e47acf7f24eb08bdcd2a&language=en_US&ref_=as_li_ss_il",
      count: 4,
    },
    {
      amp: 100,
      volt: 48,
      model: "48V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08RSLP1PQ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08RSLP1PQ?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=8745111329b17ed1fb019ac3a5abb61a&language=en_US&ref_=as_li_ss_il",
      count: 2,
    },
  ],
  10800: [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 9,
    },
  ],
  12000:  [
    {
      amp: 100,
      volt: 12,
      model: "12V100AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B084DB36KW&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/Ampere-Time-Rechargeable-4000-8000-Applications/dp/B084DB36KW?dchild=1&keywords=lithium%2Blifepo4%2B200a&qid=1631733805&s=automotive&sr=1-3&th=1&linkCode=li2&tag=sollife-20&linkId=40f1109137095cccb93d56be8913faa8&language=en_US&ref_=as_li_ss_il",
      count: 10,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B088RM4W48?_encoding=UTF8&psc=1&linkCode=li2&tag=sollife-20&linkId=ae01a116f34716acc2b7cbbc9d789dc5&language=en_US&ref_=as_li_ss_il",
      count: 5,
    },
    {
      amp: 200,
      volt: 12,
      model: "12V200AHPLUS",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08P6HH4WK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B08P6HH4WK?_encoding=UTF8&th=1&linkCode=li2&tag=sollife-20&linkId=fdd1cf22ade11a286306e444caf549f6&language=en_US&ref_=as_li_ss_il",
      count: 5,
    },
    {
      amp: 100,
      volt: 24,
      model: "12V200AH",
      img: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B088RM4W48&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US",
      link: "https://www.amazon.com/dp/B088RM4W48?_encoding=UTF8&psc=1&linkCode=li2&tag=sollife-20&linkId=ae01a116f34716acc2b7cbbc9d789dc5&language=en_US&ref_=as_li_ss_il",
      count: 5,
    },
  ]
};

export {
  arraySize,
  batteriesSize,
};
