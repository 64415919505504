import {Formik} from "formik";
import {Accordion, Col, Container, Form, Row} from "react-bootstrap";

import ModalInfo from "../ModalInfo";
import {
  explanations,
} from "../global";

import "./DaysUpStep.css";

const DaysUpStep = ({ totalWh = 0, dayautonomy, onDaysUpChange}) => {
  const handleDaysUpChange = (e) => {
    let value = e.target.value;
    onDaysUpChange(value)
  }
  return (
    <Container className="App-DaysUp-container">
      <Accordion className="App-step-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Step 3: Battery autonomy</Accordion.Header>
          <Accordion.Body className="App-step-body">
            <Formik
              enableReinitialize={true}
              initialValues={{
                daysUp: dayautonomy,
              }}
            >
              {({
                  handleSubmit,
                  values,
                }) => (
                <Form
                  className="form-horizontal"
                  onSubmit={handleSubmit}
                  role="form"
                >
                  <Row className="g-1">
                    <Form.Group as={Col} controlId="daysUp">
                      <Form.Label>
                        Days of autonomy:
                        <ModalInfo
                          title={explanations.daysup.title}
                          content={explanations.daysup.content}
                        />
                      </Form.Label>

                      <div className="App-DaysUp">
                        <Form.Control
                          type="number"
                          value={values.daysUp}
                          placeholder="Number of days."
                          onChange={handleDaysUpChange}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                  <Row className="App-DaysUp-total">
                    <h4>Total Watt Hour: <b>{values.daysUp * totalWh}Wh</b></h4>
                  </Row>
                </Form>
              )}

            </Formik>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
};

export default DaysUpStep;
