import { useEffect, useState } from "react";
import "./App.css";

// import Calculator from "./Calculator";
import Disclaimer from "./Disclaimer";
import ShareLinkStep from "./ShareLinkStep";
import SunHoursStep from "./SunHoursStep";
import BatteryStep from "./BatteryStep";
import DaysUpStep from "./DaysUpStep";
import TotalWhStep from "./TotalWhStep";
import TopContainer from "./TopContainer";

import { convertUrlParam } from "./tools/convert";

function App() {
  const [mainState, setMainState] = useState({
    dayautonomy: 1,
    sunhours: 5,
    appliances: [], // {id or idx: index from listAppliances, hrs or hpd: nbr hours to use per day}
  });
  const [currentAppliances, setCurrentAppliances] = useState([]);

  const [totalWh, setTotalWh] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalWhAllDays, setTotalWhAllDays] = useState(0);

  useEffect(() => {
    // Check for parameters passed to the URL to prepopulate appliances
    const {
      dayautonomy,
      sunhours,
      appliances
    } = convertUrlParam();

    setMainState({
      dayautonomy,
      appliances,
      sunhours
    });
    setCurrentAppliances(appliances);
  }, []);

  const onDaysUpChange = (value) => {
    const newState = {...mainState};
    newState.dayautonomy = value;
    setMainState(newState);
  };

  const handleOnChangeAppliances = (values) => {
    setCurrentAppliances(values);
  };

  return (
    <div className="App">
      <TopContainer />

      <SunHoursStep sunhours={mainState.sunhours} />

      <BatteryStep
        onChangeAppliances={handleOnChangeAppliances}
        appliances={mainState.appliances}
        setTotalWh={setTotalWh}
      />

      <DaysUpStep
        totalWh={totalWh}
        dayautonomy={mainState.dayautonomy}
        setTotalWhAllDays={setTotalWhAllDays}
        onDaysUpChange={onDaysUpChange}
      />

      <TotalWhStep
        sunhours={mainState.sunhours}
        totalWh={totalWh * mainState.dayautonomy}
      />

      <ShareLinkStep
        dayautonomy={mainState.dayautonomy}
        sunhours={mainState.sunhours}
        currentAppliances={currentAppliances}
      />

      {/*<Calculator />*/}

      <Disclaimer />
    </div>
  );
}

export default App;
