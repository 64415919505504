const convertUrlParam = (param = "") => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params && params.a) {
    param = params.a;
  }
  const conv = atob(param);
  // ?a=NC44OC8wLzAtMiYyLTIuMyY0LTEmMTAtMC41
  // 0/5/0-2&2-2.3&4-1&10-0.5
  const splittedConv = conv.split("/");
  const appliances = [];
  let sunhours = 5;
  let dayautonomy = 1;

  if (splittedConv && splittedConv.length === 3 && splittedConv[0]) {
    sunhours = Number(splittedConv[0]);
    dayautonomy = Number(splittedConv[1]);

    splittedConv[2].split("&").forEach((entry) => {
      const appliance = entry.split("-");
      appliances.push({
        id: Number(appliance[0]),
        hrs: Number(appliance[1])
      });
    });
  }

  return {
    dayautonomy: Number(dayautonomy) || 1, // Minimum of one day off
    appliances,
    sunhours: isNaN(sunhours) ? 5 : sunhours
  };
};

const buildUrlParam = (sunhours, appliances = [], dayautonomy) => {
  let params = sunhours + "/" + dayautonomy + "/";
  appliances.forEach((app, i) => {
    if (typeof app.idx === "number" && app.idx > -1) {
      params += app.idx;
      params += "-";
      params += app.hpd
      if (i < appliances.length-1) {
        params += "&";
      }
    } else if (typeof app.id === "number" && app.id > -1) {
      params += app.id;
      params += "-";
      params += app.hrs
      if (i < appliances.length-1) {
        params += "&";
      }
    }
  });

  const conv = btoa(params);
  const urlWithParams = new URL(window.location.origin);
  urlWithParams.searchParams.append("a", conv);
  return urlWithParams;
}

export {
  buildUrlParam,
  convertUrlParam,
};
