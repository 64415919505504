import {Accordion, Container} from "react-bootstrap";
import logo from "./images/logo2.png";

const TopContainer = () => (
  <Container className="App-why">
    <img src={logo} className="App-why-logo" alt="Solar Power Sizing Calculator"/>
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>The Solar Power Sizing Calculator tool helps to estimate your system size.</Accordion.Header>
        <Accordion.Body className="why-body">
          Thanks to our calculator, you will be able to size your PV array, batteries and MPPT base on your need.
          <br /><br />
          <b>Steps to use the off-grid calculator:</b>
          <br />
          - Enter Your Zip Code to find out your average sun hours/day in your area (or enter by hand your estimation)<br />
          - Fill Out Load Calculator base on all devices you are planning to connect to your system.<br />
          - Set how long you want to be able to be off grid<br />
          <br />
          We also offer amazon link of products base on your result when it's possible.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </Container>
);

export default TopContainer;
