import React, {Fragment} from "react";
import "./Disclaimer.css";

import {Accordion, Card, Container, useAccordionButton} from "react-bootstrap";
import {TiWarningOutline} from "react-icons/all";

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <div className="App-disclaimer-other" onClick={decoratedOnClick}>
      {children}
    </div>
  );
};

const listSites = [
  "https://www.solarwiresizecalculator.com",
  "https://www.solarinvertercalculator.com/",
  "https://www.solarbatterybankcalculator.com",
  "https://www.solarpaneldiagram.com/",
  "https://www.solarpowertoolbox.com"
];

const Disclaimer = () => (
  <div className="App-disclaimer">
      <Container>
        <div className="App-disclaimer-content">
          <Accordion className="App-disclaimer-accordion">
            <CustomToggle eventKey="0">Click to see other solar calculators</CustomToggle>

              <Accordion.Collapse eventKey="0">
                <Card>
                <Card.Body>
                  Here is a list of other solar calculators you can use.
                  {
                    listSites.map((site, i) => (
                      <Fragment key={i}>
                        <br/>
                        <a href={site}>
                          {site.split("https://")[1]}
                        </a>
                      </Fragment>
                    ))
                  }
                </Card.Body>
                </Card>
              </Accordion.Collapse>
          </Accordion>
          <div className="App-disclaimer-separator"/>
          Copyright  Solar Battery Bank Calculator© 2021
          <br />
          www.solarbatterybankcalculator.com is a participant in the Amazon Services LLC Associates Program,
          an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com
          We make a commission for sales made through affiliate links posted on this website.
          <Accordion className="App-disclaimer-accordion">
            <CustomToggle eventKey="0">
              <TiWarningOutline /> Click to see our Special mentions
            </CustomToggle>
            <Accordion.Collapse eventKey="0">
              <Card>
                <Card.Body>
                  All our tools are for informational purposes only.
                  <br />
                  This tool is provided "as is" and no responsibility will be accepted
                  by us for the use of this tool or for any outcomes that may arise by using the tool.
                </Card.Body>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </Container>
  </div>
);

export default Disclaimer;
