import React, {useEffect, useState, Fragment} from "react";
import {Accordion, Container} from "react-bootstrap";

import {
  arraySize,
  batteriesSize,
} from "./shared";

import "./TotalWhStep.css";
import Count from "./Count";

const TotalWhStep = ({ sunhours = 1, totalWh = 0}) => {
  const [possibilitiesBattery, setPossibilitiesBattery] = useState([]);

  useEffect(() => {
    const possibleBattery = [];
    for (let i=0; i<arraySize.length; i++) {
      if (arraySize[i] >= totalWh && (arraySize[i] - totalWh < 500 || !possibleBattery.length)) {
        possibleBattery.push({
          name: arraySize[i],
          models: batteriesSize[arraySize[i]],
          panel: Math.ceil(Number(arraySize[i]) / sunhours)
        });

        // Add superior model if the difference is not to big
        if ((arraySize[i] === totalWh || arraySize[i] - totalWh < 500) && arraySize[i+1]) {
          possibleBattery.push({
            name: arraySize[i+1],
            models: batteriesSize[arraySize[i+1]],
            panel: Math.ceil(Number(arraySize[i+1]) / sunhours)
          });
        }
      }
    }
    setPossibilitiesBattery(possibleBattery);
  }, [sunhours, totalWh]);

  return (
    <Container className="App-TotalWh-container">
      <h3>Total Watt per hour (Wh):</h3>
      <Count count={totalWh}/>
      {
        totalWh === 0
          ? ""
          : possibilitiesBattery.length
            ? (
              <>
                <div className="App-TotalWh-base-result">
                  {`Base on this result, you need a standard `}<b>{possibilitiesBattery[0].name}wh</b>{` which represent
                  a solar panel array of `}<b>{Math.ceil(possibilitiesBattery[0].panel/100)*100}watt</b>
                  {` (exactly ${possibilitiesBattery[0].panel}watt) based on your average sun hours per day.`}
                  <br />
                  <table>
                    <tbody>
                      <tr>
                        <td><b>~ Amp Hour per day with 12v</b></td><td>{Math.ceil(totalWh / 12)}</td>
                      </tr>
                      <tr>
                        <td><b>~ Amp Hour per day with 24v</b></td><td>{Math.ceil(totalWh / 24)}</td>
                      </tr>
                      <tr>
                        <td><b>~ Amp Hour per day with 48v</b></td><td>{Math.ceil(totalWh / 48)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Battery</Accordion.Header>
                    <Accordion.Body className="why-body">
                      {possibilitiesBattery.map((bat, i) => (
                        <p key={i}>
                          <b>You can build {bat.name}wh with:</b>
                          {bat.models.map((b, idx) => (
                            <Fragment key={idx}>
                              <br/>
                              {b.count}x batter{b.count > 1 ? "ies" : "y"} {b.volt}V {b.amp}Amps <a href={b.link}><img alt="Amazon battery link" src={b.img} /></a>
                            </Fragment>
                          ))}
                        </p>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Solar panel array</Accordion.Header>
                    <Accordion.Body className="why-body">
                      {possibilitiesBattery.map((bat, i) => (
                        <p key={i}>
                          <b>{`With ${bat.name}wh you will need at least a solar panel array of ${Math.ceil(bat.panel/100)*100}watt.`}</b>
                        </p>
                      ))}
                      {`This reliable HQST 12v 100amp panel can be use`}
                      <br/>
                      <iframe
                        title="Amazon panel link"
                        style={{width: "120px", height: "240px"}}
                        marginWidth="0"
                        marginHeight="0"
                        scrolling="no"
                        frameBorder="0"
                        src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=sollife-20&language=en_US&marketplace=amazon&region=US&placement=B07HQBN9WH&asins=B07HQBN9WH&linkId=ff37c24aef102f10860c1837689eef88&show_border=true&link_opens_in_new_window=true"
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Solar charge controller (MPPT)</Accordion.Header>
                    <Accordion.Body className="why-body">
                      {possibilitiesBattery.map((bat, i) => {
                        const panelWt = Math.ceil(bat.panel/100)*100;
                        const mppt12 = Math.ceil(panelWt/12);
                        const mppt24 = Math.ceil(panelWt/24);
                        const mppt48 = Math.ceil(panelWt/48);
                        return (
                          <p key={i}>
                            <b>{`To match a solar panel array of ${panelWt}watt`}</b>
                            <br/>
                            - With 12V, you will need an MPPT which can support {mppt12}amp<br/>
                            {
                              mppt12 > 50
                                ? (
                                  <>
                                    This is a high value and we advice to step up your voltage.
                                    <br/>
                                  </>
                                )
                                : ""
                            }

                            <br/>
                            - With 24V, you will need an MPPT which can support {mppt24}amp<br/>
                            {
                              mppt24 > 50
                                ? (
                                  <>
                                    This is a high value and we advice to step up your voltage.
                                    <br/>
                                  </>
                                )
                                : ""

                            }
                            <br/>
                            - With 48V, you will need an MPPT which can support {mppt48}amp

                            <br />
                            <br />
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                              href="https://www.amazon.com/Victron-SmartSolar-Charge-Controller-Bluetooth/dp/B073ZJ3L13?ie=UTF8&qid=1547011793&sr=8-3&keywords=victron%2Bmppt&th=1&linkCode=li1&tag=sollife-20&linkId=eb28eebed6f88d2ee4fd955068dcb396&language=en_US&ref_=as_li_ss_il"
                              target="_blank"
                            >
                              <img
                                alt="Amazon link"
                                border="0"
                                src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B073ZJ3L13&Format=_SL110_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=sollife-20&language=en_US"
                              />
                             </a>
                            As MPPT, <b>Victron</b> is a valuable brand, see a list of Victron <a href="https://amzn.to/3lsJNDk">HERE</a>
                          </p>
                        )
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            )
            : "No battery found for this power. Try different settings"
      }
    </Container>
  )
};

export default TotalWhStep;
