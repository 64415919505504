import {useEffect, useState} from "react";
import {
  Form as FormikForm,
  Field,
  FieldArray,
  Formik,
} from "formik";
import {Accordion, Button, Col, Container, Form, Row} from "react-bootstrap";
import {ImCross, GrAddCircle} from "react-icons/all";

import applianceIcon from "../images/appliance-icon.png";
import ModalInfo from "../ModalInfo";
import {listAppliances, sortAppliance} from "./listAppliances";
import {explanations} from "../global";

import "./BatteryStep.css";

const initialValuesState = {
  numberOfAppliances: 1,
  appliances: [],
  totalWattPerDay: 0,
};

const BatteryStep = ({onChangeAppliances, appliances = [], setTotalWh}) => {
  const [initialValues, setInitialValues] = useState(initialValuesState);

  useEffect(() => {
    if (appliances.length) {
      const newInitialValues = {...initialValuesState};
      appliances.forEach((app) => {
        newInitialValues.appliances.push({
          name: listAppliances[app.id].name,
          watt: listAppliances[app.id].watt,
          hpd: Number(app.hrs),
          idx: app.id,
          wph: Number(app.hrs) * Number(listAppliances[app.id].watt),
        });
        newInitialValues.numberOfAppliances += 1;
      });
      const totalWattPerDay = newInitialValues.appliances.reduce((acc, a) => {
        return Number(a.wph) + acc;
      }, 0);
      newInitialValues.totalWattPerDay = totalWattPerDay;
      setInitialValues(newInitialValues);
      setTotalWh(totalWattPerDay);
    }
  }, [appliances, setTotalWh]);

  const handleSubmitForm = () => {};

  return (
    <Container className="App-Battery-container">
      <Accordion className="App-step-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Step 2: Load list of appliances</Accordion.Header>
          <Accordion.Body className="App-step-body">
            <Formik
              onSubmit={handleSubmitForm}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              {({
                  handleSubmit,
                  handleChange,
                  values,
                  setValues,
                }) => {
                const addAppliance = () => {
                  // update dynamic form
                  const appliances = [...values?.appliances ?? []];
                  appliances.push({name: '', watt: 0, hpd: 1, wph: 0});
                  setValues({...values, appliances});
                  onChangeAppliances(appliances);
                };

                const removeAppliance = (index) => {
                  // update dynamic form
                  const appliances = [...values?.appliances ?? []];
                  if (index > -1) {
                    appliances.splice(index, 1);
                  }
                  const totalWattPerDay = appliances.reduce((acc, a) => {
                    return Number(a.wph) + acc;
                  }, 0);
                  setValues({...values, appliances, totalWattPerDay});
                  onChangeAppliances(appliances);
                  setTotalWh(totalWattPerDay);
                };

                return (
                  <Form
                    as={FormikForm}
                    className="form-horizontal App-Battery-form"
                    onSubmit={handleSubmit}
                    role="form"
                  >
                    <Row>
                      <Form.Group as={Col}>
                        <Form.Label style={{width: "auto"}}>List of appliances:</Form.Label>
                        <ModalInfo
                          title={explanations.appliances.title}
                          content={explanations.appliances.content}
                        />
                      </Form.Group>
                    </Row>
                    <FieldArray name="appliances">
                      {() => values.appliances.map((appliance, i) => {
                        const onSelectAppliance = (e) => {
                          const appliances = [...values?.appliances ?? []];
                          const idx = e.target.value;
                          if (idx > -1) {
                            const watt = listAppliances[idx].watt;

                            appliances[i].name = listAppliances[idx].name;
                            appliances[i].watt = Number(watt);
                            appliances[i].hpd = 1;
                            appliances[i].wph = Number(watt);
                            appliances[i].idx = Number(idx);
                            const totalWattPerDay = appliances.reduce((acc, a) => {
                              return Number(a.wph) + acc;
                            }, 0);
                            setTotalWh(totalWattPerDay);
                            setValues({...values, appliances, totalWattPerDay});
                            onChangeAppliances(appliances);
                          }
                        };

                        const onChangeWatt = (e) => {
                          const appliances = [...values?.appliances ?? []];
                          let watt = Number(e.target.value);

                          appliances[i].watt = watt;
                          appliances[i].wph = watt * Number(appliances[i].hpd);
                          const totalWattPerDay = appliances.reduce((acc, a) => {
                            return Number(a.wph) + acc;
                          }, 0);
                          setTotalWh(totalWattPerDay);
                          setValues({...values, appliances, totalWattPerDay});
                          onChangeAppliances(appliances);
                        }

                        const onChangeHPD = (e) => {
                          const appliances = [...values?.appliances ?? []];
                          let hpd = e.target.value;

                           // Limiter
                          if (hpd > 24) {
                            hpd = 24
                          }

                          appliances[i].hpd = Number(hpd);
                          appliances[i].wph = Number(appliances[i].watt * hpd);
                          const totalWattPerDay = appliances.reduce((acc, a) => {
                            return Number(a.wph) + acc;
                          }, 0);
                          setTotalWh(totalWattPerDay);
                          setValues({...values, appliances, totalWattPerDay});
                          onChangeAppliances(appliances);
                        };

                        const originValue = listAppliances.findIndex((e) => {
                          return e.name === values.appliances[i].name
                        });

                        return (
                          <Row key={i} className="App-Battery-appliance">
                            <div
                              className="App-Battery-appliance-trash"
                              onClick={() => removeAppliance(i)}
                            >
                              <ImCross title="Remove"/>
                            </div>

                            <Row className="App-Battery-appliance-select">
                              <Col className="App-Battery-col">
                                <div className="App-Battery-appliance-electric">
                                  <img alt="Appliance" width={25} src={applianceIcon} />
                                </div>
                                <Form.Select
                                  aria-label="appliance"
                                  value={originValue}
                                  onChange={onSelectAppliance}
                                  name="appliance"
                                >
                                  <option value={-1}>Select an appliance</option>
                                  {listAppliances
                                    .sort(sortAppliance)
                                    .map((option, idx) => (
                                      <option key={idx} value={idx}>{option.name}</option>
                                    ))}
                                </Form.Select>
                              </Col>
                            </Row>

                            <Row className="App-Battery-appliance-details">
                              <Col>
                                <label>Watt</label>
                                <Field
                                  name={`appliances.${i}.watt`}
                                  type="number"
                                  className="form-control"
                                  onChange={onChangeWatt}
                                />
                              </Col>
                              <Col>
                                <label>Hours On / Day</label>
                                <Field
                                  name={`appliances.${i}.hpd`}
                                  type="number"
                                  className="form-control"
                                  onChange={onChangeHPD}
                                />
                              </Col>
                              <Col>
                                <label>Watt Hours / Day</label>
                                <Field
                                  disabled
                                  name={`appliances.${i}.wph`}
                                  type="number"
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                          </Row>
                        );
                      })}
                    </FieldArray>


                    <Row className="App-Battery-appliance-total">
                      <Col>
                        <Button
                          onClick={addAppliance}
                          variant="warning"
                          style={{width: "100%"}}
                        >
                          <GrAddCircle/>{" ADD APPLIANCE"}
                        </Button>
                      </Col>
                      <Form.Group as={Col} controlId="zipcode">
                        <Form.Label className="App-Battery-total-watt">
                          Total Watt Hours per Day:
                        </Form.Label>
                        <Form.Control
                          type="number"
                          disabled
                          step="0.01"
                          value={values.totalWattPerDay}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                  </Form>
                )
              }}

            </Formik>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
};

export default BatteryStep;
